import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    gallery: {
        // marginLeft: '2vw',
        // marginTop: '2vh',
        // overflowX: 'hidden',
        paddingLeft: '2vw',
        paddingTop: '2vh'
    }
}));

export default function Gallery(props) {
  const classes = useStyles();

  return (
      <>
        <Grid
          container
          className={classes.gallery}
        >
          {props.appList.map((app) => (
            <Grid key={`app-${app.id}`} item>
              <a href={app.link} style={{textDecorationLine: 'none'}}>
                <Box>
                  <Tooltip
                    title={app.title}
                    TransitionComponent={({children}) => children} //disable animation
                  >
                    {app.img()}
                  </Tooltip>
                </Box>
              </a>
            </Grid>
          ))}
        </Grid>
      </>
  );
};