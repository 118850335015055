import { makeStyles } from '@material-ui/core/styles';
import Appbar from './container/appbar.js';
import Gallery from './container/gallery.js';
import FuckedDiceLogo from './assets/fuckedDice-logo.svg';

const useStyles = makeStyles((theme) => {
  const defaultTransition = {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      transform: 'scale(1.2)',
    }
  };

  return {
    '@keyframes circularAnimation' : {
        '0%': {
            transform: 'rotate(0deg) translate(-165px) rotate(0deg)'
        },
        '100%': {
            transform: 'rotate(360deg) translate(-165px) rotate(-360deg)'
        }
    },
    fuckedDice: Object.assign({}, defaultTransition, {
        // animation: '$circularAnimation 6s linear infinite',
        height:'120px',
    }),
}});

function App() {

  const classes = useStyles();

  const appList = [
    {
      id: 'fucked-dice',
      title: 'SUA VEEEEZ!',
      img: () => <img src={FuckedDiceLogo} alt='Fucked Dice' className={classes.fuckedDice}/>,
      link: 'https://suavez.nfoioq.gkmelo.com/',
    }
  ];

  return (
    <>
      <Appbar />
      <Gallery appList={appList}/>
      <p
        style={{
          position: "absolute",
          left: "0",
          bottom: "0",
          right: "0",
          textAlign: 'center'
        }}
      >
        Copyright © nfoioq 2021 - Usando a programação para o bem
      </p>
    </>
  );
}

export default App;
